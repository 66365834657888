body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif;
    padding-top: 80px;
}

a {
    color: #333;
    text-decoration: none
}

a:active {
        text-decoration: none;
    }

code {
    color: #00cc80;
    background: #fafafa !important;
    font-family: Menlo, Consolas, monospace;
    line-height: 1.5;
    font-size: 14px;
    margin: 1rem 0;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 22px;
}

h4 {
    font-size: 20px;
}

h5,
h6 {
    font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 3rem 0 1rem;
}

p,
li {
    margin: 1rem 0;
    line-height: 1.6;
}

li {
    margin: .5rem 0;
}

a {
    color: #000;
    font-weight: bold
}

a:hover {
        text-decoration: underline;
    }

img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
}

ul,
ol {
    line-height: 2;
}

blockquote {
    position: relative;
    background: #fafafa;
    margin: 0;
    padding: 10px 0;
    padding-left: 30px;
    border-left: 3px solid #999;
    box-sizing: border-box;
}

blockquote p {
        margin: 0;
    }
.follow {
    margin-top: 20px;
}
.follow .description {
        text-align: center;
        color: #333;
    }
.follow .description span {
            font-weight: bold;
        }
a[title="站长统计"] {
    display: none;
}
.container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 2rem;
}
.text-center {
    text-align: center;
}
body[data-page="home"] h2 {
        margin: 0;
        margin-bottom: 20px;
    }
body[data-page="home"] p {
        margin: 14px 0;
    }
body[data-page="home"] .article {
        position: relative;
        padding: 30px 0;
        border-bottom: #EBF2F6 1px solid
    }
body[data-page="home"] .article:after {
            display: block;
            content: "";
            width: 7px;
            height: 7px;
            border: #E7EEF2 1px solid;
            position: absolute;
            bottom: -5px;
            left: 50%;
            margin-left: -5px;
            background: #FFF;
            border-radius: 100%;
            box-shadow: #FFF 0 0 0 5px;
        }
.article-meta {
    color: #666;
}
.article-content {
    margin-top: 50px;
}
.page-content {
    color: #333;
    overflow: hidden;
}
.Header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.Nav {
    position: relative;
}
.Nav .container {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
                align-items: center;
        -webkit-box-pack: justify;
                justify-content: space-between;
        height: 80px;
    }
.Nav .Logo {
        width: 60px;
        background: #fafcfb;
        border-radius: calc(60px / 2);
        overflow: hidden;
    }
.Nav .Logo img {
            width: 100%;
            height: 100%;
        }
.Nav .menu {
        margin: 0;
        padding: 0;
        list-style: none;
        float: right;
        height: 80px;
        line-height: 80px;
    }
.Nav .menu li {
            display: inline-block;
            margin-left: 20px;
        }
.Nav .menu a {
            font-weight: bold;
        }
@media screen and (max-width: 750px) {

    .Nav .menu {
            display: none
    }
        }
.Nav .icon-menu {
        width: 40px;
        height: 40px;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNTczNzg0NzU0NDgxIiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjI3NTYiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iOTAiIGhlaWdodD0iOTAiPjxkZWZzPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+PC9zdHlsZT48L2RlZnM+PHBhdGggZD0iTTEyOCA1MTJtLTY0IDBhNjQgNjQgMCAxIDAgMTI4IDAgNjQgNjQgMCAxIDAtMTI4IDBaIiBmaWxsPSIiIHAtaWQ9IjI3NTciPjwvcGF0aD48cGF0aCBkPSJNODk2IDUxMm0tNjQgMGE2NCA2NCAwIDEgMCAxMjggMCA2NCA2NCAwIDEgMC0xMjggMFoiIGZpbGw9IiIgcC1pZD0iMjc1OCI+PC9wYXRoPjxwYXRoIGQ9Ik01MTIgNTEybS0xNjAgMGExNjAgMTYwIDAgMSAwIDMyMCAwIDE2MCAxNjAgMCAxIDAtMzIwIDBaIiBmaWxsPSIiIHAtaWQ9IjI3NTkiPjwvcGF0aD48L3N2Zz4=);;
        background-position: center;
        background-size: cover;
        cursor: pointer
    }
@media screen and (min-width: 750px) {

    .Nav .icon-menu {
            display: none
    }
        }
.menu-mobile {
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    z-index: 9;
    text-align: center;
    padding-top: 50px;
    margin: 0;
}
.menu-mobile li {
        margin: 30px 0;
    }
.menu-mobile.visible {
        opacity: 1;
        visibility: visible;
        -webkit-transition: opacity .3s;
        transition: opacity .3s;
    }
@media screen and (min-width: 750px) {
.menu-mobile {
        display: none
}
    }
.Pagination {
    margin-top: 20px;
    padding: 10px 0;
}
.Pagination .page-prev,
    .Pagination .page-next {
        display: block;
        width: 100px;
        height: 40px;
        line-height: 40px;
        border-radius: 10px;
        font-size: 24px;
        text-align: center;
        box-shadow: 0 0 2px #aaa;
    }
.Pagination .page-prev {
        float: left;
    }
.Pagination .page-next {
        float: right;
    }
.Footer {
    padding: 100px 0;
}
.Footer .copyright {
        color: #333;
        font-weight: bold;
        letter-spacing: 6px;
    }
.Avatar {
    width: 200px;
    height: 200px;
    border-radius: calc(200px / 2);
    overflow: hidden;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    margin: 30px 0
}
.Avatar:hover {
        box-shadow: 0px 0px 30px rgba(230, 230, 230, 1);
        -webkit-transition: -webkit-transform .3s;
        transition: -webkit-transform .3s;
        transition: transform .3s;
        transition: transform .3s, -webkit-transform .3s;
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }
.About .bio {
        text-align: center;
    }
.About .avatar-wrap {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
                align-items: center;
        -webkit-box-pack: center;
                justify-content: center;
    }
